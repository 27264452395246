<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="sub-title">
                <span>企业性质：{{ info.settingName }}</span>

                <span>行业领域：{{ info.fieldName }}</span>
                <span>所属地区：{{ info.owner }}</span>
            </div>
            <div class="content" v-html="info.manual"></div>
        </div>
    </bread-pages>
</template>

<script>
import BreadPages from '../../components/page/BreadPages.vue';
import { digitalTypeOptions } from '../../utils/variables';
export default {
    components: { BreadPages },
    data() {
        return {
            loading: false,
            info: {},
            digitalTypeOptions
        };
    },
    computed: {},
    mounted() {
        this.loading = true;
        this.$http.get('company/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);
            this.$EventBus.$emit('changePreTitle', {
                name: '企业',
                path: '/digital',
                query: {
                    type: 'COMPANY'
                }
            });
            this.$EventBus.$emit('changeTitle', '企业详情');
            document.title = '企业详情';
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 0 150px 60px;
    box-sizing: border-box;
    .min-height(606px);
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 34px;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid @bg;
}
.sub-title {
    .flex();
    justify-content: center;
    font-size: 14px;
    color: #939599;
    line-height: 24px;
    span + span {
        margin-left: 40px;
    }
    padding: 20px 0 40px;
}

.content {
    line-height: 28px;
    p {
        font-size: 14px;
        color: #000000;
        line-height: 28px;
    }
}
.appContainer {
    background: #ffffff !important;
}
</style>
