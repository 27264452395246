<template>
    <div class="bread-pages">
        <div class="center-content">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item v-if="preTitle && !fromHome" :to="{ path: preTitle.path, query: preTitle.query }">{{
                    preTitle.name
                }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ routerName }}</el-breadcrumb-item>
            </el-breadcrumb>

            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'BreadPage',
    computed: {
        ...mapState(['historys']),
        routerName() {
            return this.pageTitle || this.$route.meta?.title;
        },
        fromHome() {
            return !!this.$route.query.fromHome;
        }
    },
    data() {
        return {
            pageTitle: '',
            preTitle: null
        };
    },
    mounted() {
        this.$EventBus.$on('changeTitle', res => {
            this.pageTitle = res;
        });
        this.$EventBus.$on('changePreTitle', res => {
            this.preTitle = res;
        });
    },
    beforeDestroy() {
        this.$EventBus.$off('changeTitle');
        this.$EventBus.$off('changePreTitle');
    }
};
</script>

<style lang="less" scoped>
.bread-pages {
    padding: 30px 0 !important;
}
.el-breadcrumb {
    padding-bottom: 30px;
}
.el-breadcrumb::before {
    content: '当前页面：';
    float: left;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    line-height: 1;
}
/deep/ .el-breadcrumb__inner {
    color: #c8c9cc !important;
}
/deep/ .is-link {
    color: rgba(0, 0, 0, 0.6) !important;
}
</style>
